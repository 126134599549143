export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "Fisch Roblox",
    slogan: "Cast, Catch, Explore!Fisch Roblox Game",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    gameIframe: "https://game.fischroblox.fun/Robbie_ Super Fishing (by GB Games)_ Play Online For Free On Playhop.html",
    domain: "https://fischroblox.top",
    gaId: "G-Q7RJGNPKWF",
    navbarItems: [
        {
            title: "title",
             href: "/",
        }
    ],
}
